@import url(http://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);

.smartphone-swiper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: auto;
    border-radius: 15px;
    width: clamp(290px, 49vh, 360px);
    height: clamp(475px, 85vh, 600px);
}

.screen-swiper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    position: relative;
}

.topbar-swiper {
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 101.5%;
    padding: 2px 2%;
    position: relative;
    top: -2px;
    height: 20px;
    background: #ffffff;
    color: white;
    font-size: 15px;
    border-radius: 7px 7px 0 0;
    font-family: monospace;
}

.topbar-left-swiper {
    display: none;
    align-items: center;
}

.topbar-middle-swiper {
    margin-left: 9px;
    display: none;
}

.topbar-middle-swiper::before {
    display: none;
    content: '';
    background: black;
    width: 10px;
    height: 50px;
    top: 248px;
    right: -11px;
    border-radius: 5px;
    position: absolute;
}

.clock-swiper {
    padding-right: 9px;
    display: none;
}

.camera-swiper {
    background: black;
    width: 54px;
    height: 10.2px;
    position: relative;
    top: 6.3px;
    border-radius: 0 0 20px 20px;
}

.camera-swiper::before {
    content: '';
    position: absolute;
    width: 31.7px;
    height: 16px;
    border-radius: 43%;
    top: 3.7px;
    left: -13.7px;
    background: #838383;
}

.camera-swiper::after {
    content: '';
    position: absolute;
    width: 31.7px;
    height: 16px;
    border-radius: 43%;
    top: 3.7px;
    left: 42.9px;
    background: #ffffff;
}

.camera-lens-swiper {
    background: black;
    width: 25px;
    height: 25px;
    top: -7px;
    left: 18px;
    border-radius: 50%;
    position: relative;
}

.camera-lens-swiper::before {
    content: '';
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    top: 7px;
    left: 6px;
    background: #2c3c3c;
}

.camera-lens-swiper::after {
    content: '';
    position: absolute;
    width: 3px;
    height: 12px;
    transform: rotate(45deg);
    top: 9.9px;
    left: 13.2px;
    background: #ffffff9e;
    border-radius: 0 20px 20px 0;
}

.inner-lens-swiper {
    position: relative;
    width: 5px;
    height: 5px;
    background: #ffffffa8;
    border-radius: 50%;
    top: -20.8px;
    left: 28px;
}

.inner-lens-swiper::after {
    content: '';
    position: absolute;
    width: 1.8px;
    height: 1.8px;
    transform: rotate(45deg);
    top: 1.6px;
    left: 1.6px;
    background: #00000085;
    border-radius: 50%;
}

.topbar-right-swiper {
    font-family: monospace;
}

.topbar-right-swiper::before {
    content: '';
    background: black;
    width: 10px;
    height: 140px;
    top: 80px;
    right: -11px;
    border-radius: 5px;
    position: absolute;
}

.topbar-right-swiper::after {
    content: '';
    background: #e4edfb;
    width: 10px;
    height: 12px;
    top: 149px;
    right: -19.3px;
    border-radius: 5px;
    position: absolute;
}

.screen-swiper footer {
    display: none;
    align-items: center;
    justify-content: space-around;
    width: 102%;
    padding: 8px;
    margin-bottom: -2px;
    height: 25px;
    background: black;
    color: white;
    font-size: 15px;
}

.navbar-swiper {
    display: none;
    justify-content: space-around;
    align-items: baseline;
    padding: 10px 0;
    width: 100%;
    height: 45px;
}

.navbar-swiper i {
    cursor: pointer;
}

.person-swiper {
    width: 100%;
    height: 100%;
}

.photo-swiper {
    margin: auto;
    height: 100%;
    border-radius: 10px;
    display: flex;
    align-items: flex-end;
    color: #eee;
    cursor: grab;
    transition: 300ms;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.image-swiper-photo{
    width: 100%;
    height: 100%;
    background-size: cover;
}

.photo-swiper.moving {
    transition: none;
    cursor: grabbing;
}

.photo-swiper.nope-swiper::after {
    content: "NOPE";
    color: #fe466d;
    border: 6px solid #fe466d;
    border-radius: 8px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 3rem;
    padding: 0.2rem 0.4rem;
    position: absolute;
    top: 8%;
    right: 8%;
    transform: rotate(15deg);
}

.photo-swiper.super_like-swiper::after {
    content: "SUPER LIKE";
    color: #08a4ef;
    border: 6px solid #08a4ef;
    border-radius: 8px;
    font-family: "Roboto", sans-serif;
    text-align: center;
    font-weight: 500;
    font-size: 2.8rem;
    padding: 0.2rem 0.4rem;
    position: absolute;
    width: 150px;
   
    transform: rotate(-15deg);
}

.photo-swiper.like-swiper::after {
    content: "LIKE";
    color: #1be4a1;
    border: 6px solid #1be4a1;
    border-radius: 8px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 3rem;
    padding: 0.2rem 0.4rem;
    position: absolute;
    top: 8%;
    left: 8%;
    transform: rotate(-15deg);
}

.personal-swiper {
    padding: 15px;
    width: 100%;
    background: linear-gradient(180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.41) 24%);
    border-radius: 10px;
}

.name-age-swiper {
    display: flex;
    align-items: baseline;
    margin-bottom: 6px;
}

.name-swiper {
    font-size: 2rem;
    font-weight: 500;
}

.age-swiper {   
    font-size: 1.6rem;
    margin-left: 10px;
    font-weight: 400;
}

.data-swiper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.about-swiper {
    display: flex;
}

.about-icon-swiper,
.about-text-swiper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.about-icon-swiper {
    align-items: center;
}

.about-text-swiper {
    align-items: flex-start;
    margin-left: 6px;
}

.about-icon-swiper i,
.about-text-swiper p {
    padding: 4px 0;
}

.commands-swiper {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100px;
}

.command-swiper {
    display: flex;
    align-items: center;
    justify-content: center;
    background: hsl(206deg 100% 73.3% / 10%);;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    font-size: 2rem;
    box-shadow: 0 2px 6px 0 rgba(112, 125, 134, 0.14);
    transition: 300 ease;
    cursor: pointer;
}

.command-swiper i {
    transition: 500ms ease;
}

.commands-swiper .command-swiper:nth-child(2n + 1) {
    height: 48px;
    width: 48px;
    font-size: 1.5rem;
}

.command-swiper:hover {
    transform: scale
    (1.1);
    color: #fff;
    box-shadow: 0 2px 6px 0 rgba(112, 125, 134, 0.33);
}

.command-swiper:hover i {
    transform: scale(1.15);
}

/* Icon size and colors */
.fa-circle-user-swiper,
.fa-comment-dots-swiper {
    color: #dadfe6;
    font-size: 1.5rem;
}

.fa-fire-flame-curved-swiper {
    color: #fe466d;
    font-size: 1.7rem;
}


.fa-circle-info-swiper {
    cursor: pointer;
    transition: 500ms ease;
}

.fa-circle-info-swiper:hover {
    transform: scale(1.1);
    color: #fff;
}

.fa-arrow-rotate-left-swiper {
    color: #f7e707;
}

.fa-close-swiper {
    color: #fd5068;
}

.fa-star-swiper {
    color: #2db1ff;
}

.fa-heart-swiper {
    color: #1be4a1;
}

.fa-bolt-swiper {
    color: #e211e2;
}
