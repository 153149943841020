// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// Colors

$theme-color: #f24570;
$theme-color2: #00c851;
$theme-color3: #00afff;
$theme-color4: #a650ff;
$theme-color5: #ff6100;
$theme-color6: #2ba6cb;
$theme-color7: #ff0461;
$theme-color8: #c7edff;
$theme-color9: #233d4e;
$theme-color10: #c6d92f;
$theme-color11: #0eb8b7;
$theme-color12: #f58c76;
$body-color: #0F1B33;
$body-color2: #e0e0e0;
$body-color3: #15233E;
$body-color4: #f1f7fe;
$border-color: #ecf0f3;
$white-color: #fff;
$black-color: #222C38;
$ash-color:  #f9f9f9;
$title-color: #213366;
$desc-color: #555555;
$grey-color: #555;
$shadow-color: #888;



// social-media color
$facebook: #3b5998;
$twitter: #55acee;
$linkedin: #007bb5;
$skype: #2fb7df;
$youtube: #cd201f;
$google: #dd4b39;
$camera: #e4405f;
$instagram: #e4405f;
$tumblr: #34465d;
$behance: #1769ff;
$dribble:#ea4c89;
$dribbble:#ea4c89;
$twitch:#6441a5 ;
$star: #e9ba17;
$green: #0be881;
$green-h: #0fbcf9;
$red: #f53b57;
$yellow: #dec32b;
$nell: #212fb4;
$navy: #d8e0f3;
$plaseholder: #a0a6bc;
$pinterest : #bd081c;
$basketball : #e94c89;
$globe : #ff5e9c;
$vimeo : #3b5998;

//== Typography



$font: 'Public Sans', sans-serif;


// font-size
$fs-base: 1rem !default;
$fs-small: 0.875rem; //14px
$fs-h1: 3.75rem; //60px;
$fs-h2: 3rem; //48px;	
$fs-h3: 1.875rem; //30px;		
$fs-h4: 1.5rem; //24px;	
$fs-h5: 1.25rem; //20px;		
$fs-h6: 1.125rem; //18px;		


// Description line height
$lh: 1.5;



$transition: all .3s ease;

// Container size 

// $max-container: 1170px;


