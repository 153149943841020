.sidebar {
  /* flex: 1; */
  padding: 20px;
  border-right: 1px solid #ccc;
}

.sidebar h3 {
  margin-bottom: 10px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  cursor: pointer;
  padding: 8px;
  margin-bottom: 5px;
  border-radius: 4px;
}

.sidebar li:hover {
  background-color: #f5f5f5;
}

.chat-container {
  flex: 3;
  padding: 20px;
}

.message-list-container {
  max-height: 400px;
  overflow-y: auto;
}

.message {
  padding: 8px;
  margin: 8px;
  border-radius: 8px;
  max-width: 70%;
  background-color: #e1ffc7;
  display: flex;
  align-items: center;
}

.profile {
  margin-right: 8px;
  font-weight: bold;
}

.active-user .message {
  background-color: #c1e5b9;
}

.message-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 25%;
}

.message-input-container input {
  flex: 1;
  padding: 8px;
  margin-right: 10px;
  /* border: 1px solid #ddd; */
  border-radius: 4px;
}

.message-input-container button {
  padding: 8px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.message-input-container button:hover {
  background-color: #45a049;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

input.message-chat {
  border: 1px solid #ecf0f3;
}

form.message-chat-cls {
  display: flex;
}

button.btnn-submit {
  top: 0;
  left: 100%;
  background: #f24570;
  color: #fff;
  padding-inline: 15px;
  border-radius: 0 3px 3px 0;
}

.custom-message-name {
  margin-left: 10px;
  color: #ef5370;
  margin-top: 7px;
}
.custom-message-text {
  margin-left: 10px;
  font-size: 20px;
}

/* media query only responsive  */

@media (max-width: 600px) {
  .messenger-container {
    flex-direction: column;
  }

  .sidebar {
    border-right: none;
  }

  .chat-container {
    flex: none;
  }

  .message-list-container {
    max-height: none;
  }
}

@media (max-width: 600px) {
  .sidebar.visible {
    display: block;
  }

  .sidebar.hidden {
    display: none;
  }
  button.custm-back {
    display: block !important;
  }
  .messenger-container {
    display: flex;
    height: 700px;
    margin: auto;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
  }
}

button.custm-back {
  display: none;
}

/* Hide chat-page  footer */
.container-fluid.message-page footer.footer.overflow-hidden {
  display: none;
}

/* send a message */
.message {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.current-user {
  justify-content: flex-start;
}

.other-user {
  justify-content: flex-end;
}

.phone-call-icons {
  margin-left: 80%;
}
.messenger-header {
  background-color: #f1f7fe;
  padding: 10px;
}
.sidebar.custom-side {
  border-right: none;
}

.messenger-container {
  display: flex;
  height: 700px;
  margin: auto;
  /* padding: 20px; */
  border-radius: 8px;
  background-color: #fff;
}

.upload-button {
  margin-right: 10px;
}

.receiver-message {
  justify-content: flex-start;
}
.sidebar.custom-cls {
  border-right: none;
}
.sidebar.custom-cls ul {
  font-size: 20px;
}
h5.custom-username {
  font-size: 20px;
}
